import React, { Suspense } from "react";
import Spline from "@splinetool/react-spline";
import "./spline.css"; // Import the CSS file

const SplineAsset = () => {
  return (
    <div className="flexWrapper">
        <Spline scene="https://prod.spline.design/Aw0t6cVQ05tKdKG1/scene.splinecode" />
    </div>
  );
};

export default SplineAsset;
