import React from "react";
// import videoSource3 from "./Assets/Videocomponentshowcase.m4v";
// import videoSource4 from "./Assets/Room.m4v";
import SplineAsset from "./spline";
function VideoComponent() {
  return (
    <SplineAsset />
  );
}
export default VideoComponent;
