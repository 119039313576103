import React from "react";
import { Helmet } from "react-helmet";

const JsonLd = () => {
  const schemaOrganization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Example Organization",
    url: "https://webwizardsg.com/",
    logo: "https://github.com/WebWizardsSG/WebWizadsPages/blob/main/Assets/webwizards.png?raw=true",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+65 82872532",
      contactType: "Customer Service",
      contactOption: "TollFree",
      areaServed: "SG",
      availableLanguage: ["English"],
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "Pabruy Hill Condominium",
      addressLocality: "Singapore",
      postalCode: "467301",
      addressCountry: "SG",
    },
    sameAs: ["https://www.instagram.com/web_wizardssg"],
    founder: {
      "@type": "Person",
      name: "Chen Yangaung",
      jobTitle: "Founder & Lead Developer",
    },
    foundingDate: "2024-05-01",
    description:
      "Web Wizards is a leading web development and data analytics company based in Singapore. We specialize in creating dynamic and user-friendly websites and providing insightful data analytics services.",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://webwizardsg.com/",
    },
    mainEntity: [
      {
        "@type": "Question",
        name: "How do I contact Web Wizards?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "You can contact Web Wizards by visiting our Instagram at https://www.instagram.com/web_wizardssg",
        },
      },
      {
        "@type": "Question",
        name: "What services does Web Wizards offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Web Wizards offers a range of services including web development, data analytics, and digital marketing solutions tailored to meet your business needs including SEO and Data Analytics.",
        },
      },
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrganization)}
      </script>
    </Helmet>
  );
};

export default JsonLd;
