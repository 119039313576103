import React, { Suspense, useState, useEffect } from "react";
import { lazy } from "react";
import ReactDOM from "react-dom/client";

// Eagerly loaded components
import NavbarComponent from "./Navbar";
import VideoComponent from "./mainVideo";
import JsonLd from "./JSON-LD";
import LoadingSpinner from "./LoadingSpinner";

// Lazy loaded components
const GridContainer = lazy(() => import("./gridContainer"));
const GridContainer2 = lazy(() => import("./gridContainer2"));
const FooterComponent = lazy(() => import("./footer"));
const ColorCycleComponent = lazy(() => import("./colorCycle"));
const HorizontalBanner = lazy(() => import("./HorizontalBanner"));
const Reviews = lazy(() => import("./reviews"));
const Pptx = lazy(() => import("./pptx"));
const Facebook = lazy(() => import("./facebook"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const generalH1 = {
  textAlign: "center",
  fontFamily: "League Spartan",
  color: "#003C43",
  fontWeight: "900",
  marginTop: "3em",
  marginBottom: "1em",
};

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Error: {this.state.error.toString()}</h1>;
    }

    return this.props.children;
  }
}

function App() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("App component mounted");
    
    const timeoutId = setTimeout(() => {
      if (!isVideoLoaded) {
        console.log("Video load timeout reached. Loading other components.");
        setIsVideoLoaded(true);
      }
    }, 5000);

    return () => {
      console.log("App component will unmount");
      clearTimeout(timeoutId);
    };
  }, [isVideoLoaded]);

  useEffect(() => {
    if (isVideoLoaded) {
      // This effect will run after all components have rendered
      setIsLoading(false);
      console.log("All components loaded");
    }
  }, [isVideoLoaded]);

  const handleVideoLoad = () => {
    console.log("Video loaded successfully");
    setIsVideoLoaded(true);
  };

  const handleError = (err) => {
    console.error("Error in lazy-loaded component:", err);
    setError(err);
    setIsLoading(false);
  };

  console.log("Rendering App component. isVideoLoaded:", isVideoLoaded, "isLoading:", isLoading);

  return (
    <ErrorBoundary>
      <JsonLd />
      <NavbarComponent />
      <VideoComponent onVideoLoad={handleVideoLoad} />
      {isVideoLoaded && (
        <Suspense fallback={<LoadingSpinner />}>
          <ErrorBoundary>
            <HorizontalBanner />
            <h1 style={generalH1} id="Services">
              Our Core Services
            </h1>
            <GridContainer />
            <GridContainer2 />
            <Pptx />
            <Reviews />
            <ColorCycleComponent />
            <Facebook />
            <FooterComponent />
          </ErrorBoundary>
        </Suspense>
      )}
      {isLoading && <LoadingSpinner />}
      {error && <div>Error loading components: {error.toString()}</div>}
    </ErrorBoundary>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);