import React, { useState, useEffect, Suspense } from "react";
import companyLogo from "./Assets/webwizards.webp";
import "./navbar.css";

//-----------------CSS styling for the payment component-----------------

const navWrapper = {
  width: "100% !important",
};
const banner = {
  backgroundColor: "#003C43",
  color: "white",
  margin: "0",
  paddingBottom: "0.5em",
  paddingTop: "1.1em",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  overflow: "hidden !important",
};

const logoStyle = {
  width: "20%",
};

const topNavigation = {
  backgroundColor: "#135D66",
  position: "sticky",
  top: 0,
  zIndex: 1001, // Ensure it stays above other components
  overflow: "hidden !important",
};

const navlinks = {
  display: "flex",
  justifyContent: "center",
  fontSize: "1.5em",
  flexDirection: "row", // Default direction for larger screens
  fontFamily: "League Spartan",
  width: "100% !important",
};

const navlinksMobile = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  fontFamily: "League Spartan",
  width: "100% !important",
};

const topNavigationA = {
  display: "inline-block",
  color: "white",
  textAlign: "center",
  padding: "1% 7%",
  textDecoration: "none",
  fontFamily: "League Spartan",
  overflow: "hidden !important",
  fontSize: "5em !important",
};

const hamburgerStyle = {
  display: "block",
  fontSize: "30px",
  color: "white",
  cursor: "pointer",
  textAlign: "center",
};

//-----------------END CSS styling for the payment component-----------------

function NavbarComponent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={navWrapper}>
      <div style={banner}>
        <Suspense fallback={<div>Loading...</div>}>
          <img
            src={companyLogo}
            style={logoStyle}
            className="CompanyLogo"
            alt="Company Logo"
          />
        </Suspense>
      </div>

      <div style={topNavigation}>
        {isMobileView && (
          <div style={hamburgerStyle} onClick={toggleMenu}>
            &#9776;
          </div>
        )}
        <div
          style={
            isMobileView
              ? isMenuOpen
                ? navlinksMobile
                : { display: "none" }
              : navlinks
          }
        >
          <a style={topNavigationA} className="navlinksA" href="#Services">
            Services
          </a>
          <a
            style={topNavigationA}
            className="navlinksA"
            href="https://webwizardssg.github.io/WebWizadsPages/"
          >
            Products
          </a>
          <a
            style={topNavigationA}
            className="navlinksA"
            href="https://webwizardssg.github.io/WebWizadsPages/Demo.html"
          >
            Showcase
          </a>
          <a
            style={topNavigationA}
            className="navlinksA"
            href="https://webwizardssg.github.io/WebWizadsPages/contact.html"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavbarComponent;
